<template>
  <NewContainerCard :locationMsg="locationMsg">
    <div class="InfoDetails">
      <div class="infoDetailsContent">
        <div class="infoDetailsLeft">
          <div class="article">
            <div class="articleContent" >
              <!-- <h3>{{ BizinfoData.title }}</h3> -->
              <div class="title">{{ BizinfoData.title }}</div>
              <p>
                <!-- <span>
                  <span class="tit">发布人</span>
                  {{ BizinfoData.make_user_name }}
                </span> -->
                <span>
                  <span class="tit">发布时间</span>
                  {{ BizinfoData.send_data }}
                </span>
                <span>
                  <span class="tit">信息来源</span>
                  <span class="information" :title="BizinfoData.info_source">{{ BizinfoData.info_source }}</span>
                </span>
              </p>
            </div>
            <!-- <div v-if="BizinfoData.thumb" class="consult-box">
              <img :src="httpConfig.baseURL+BizinfoData.thumb" >
            </div> -->
            <div class="articleText" style="line-height: 20px" v-html="content">
              <!-- {{ content }} -->
            </div>
          </div>
        </div>
        <div class="infoDetailsRight">
          <Classify @selectTag="jumpInfo"></Classify>
          <Hot @jumpDetails="jumpDetail"></Hot>
        </div>
      </div>
    </div>
  </NewContainerCard>
</template>
<script>
import Location from "../../components/Location.vue";
import Classify from "../../components/Info/Classify.vue";
import Hot from "../../components/Info/Hot.vue";
import NewContainerCard from "../../components/newContainerCard.vue";
import { getBizinfoById } from "../../api/info/info.js";
import httpConfig from "../../common/utils/config";
export default {
  inject: ["reload"],
  data() {
    return {
      httpConfig,
      BizinfoData: {
        title: "",
        make_user: "",
        modify_time: "",
      },
      content: "",
      locationMsg: [
        { name: "政策公告", router: "ImageText" },
        { name: "资讯详情", router: "imageText/infoDetails" },
      ],
    };
  },
  created() {
    let id = this.$route.query.id;
    this.getData(id);
  },
  methods: {
    reloadFun() {
      this.reload();
    },
    jumpInfo(type) {
      this.$router.replace({
        path: "/imageText",
        query: {
          type: type,
        },
      });
    },
    jumpDetail(id) {
      this.$router.replace({
        path: "/imageText/infoDetails",
        query: {
          id: id,
        },
      });
      this.reloadFun();
    },
    async getData(id) {
      let BizinfoByIdRes = await getBizinfoById({ id: id });
      console.log(BizinfoByIdRes);
      this.BizinfoData = BizinfoByIdRes.data;
      const regex = new RegExp("<img", "gi");
      this.content = BizinfoByIdRes.data.content.replace(
        regex,
        '<img style="max-width: 100%;"'
      );
    },
  },
  components: { Location, Classify, Hot, NewContainerCard },
};
</script>
<style scoped lang="scss">
.infoDetailsContent {
  display: flex;
  flex-direction: row;
}

.infoDetailsLeft {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; */
  width: 756px;
}
.article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // text-align: center;
  // border: 1px solid #f2f2f2;
  border-radius: 5px;
  // margin-bottom: 20px;
  padding: 0px 0px;
}
.Classify {
  margin-bottom: 20px;
}
.articleContent {
  width: 756px;
  // height: 144px;
  padding: 24px 30px 6px 0px;
  box-sizing: border-box;
  .information {
    display: inline-block;
    width: 420px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    span {
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.articleContent::after {
  content: '';
  display: inline-block;
  width: 756px;
  height: 1px;
  background-color: #f1f1f1;
}
.title {
  font-size: 24px;
  font-family: Bold;
  color: #101010;
  line-height: 36px;
  height: 60px;
  margin-bottom: 24px;
}
.articleContent > h3 {
  font-size: 20px;
  font-size: 28px;
  line-height: 36px;
  // font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-family: Bold;
  font-weight: 500;
  color: #101010;
  margin-bottom: 24px;
}
.articleContent > p {
  > span {
    font-size: 12px;
    margin-right: 120px;
  }
}
.tit {
  color: #8896a7;
  margin-right: 12px;
}
.articleFlip > .el-button {
  margin: 20px;
}
.consult-box {
  margin-top: 20px;
  img {
    width:756px;
    height:394px
  }
}
.articleText {
  height: 100%;
  width: 100%;
  padding: 20px 30px 36px 30px;
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #404040;
  line-height: 36px;
  text-align: justify;
}
.infoDetailsRight {
  width: 300px;
}

@media screen and (max-width: 768px) {
}
::v-deep .locationList{
  position: absolute;
  left: -27px;
}
</style>